import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Link,
  ListItem,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import {
  HeadsetColorIcon,
  MultiDeviceColorIcon,
  ShareColorIcon,
  UsersColorIcon,
} from "../src/icons";
import { NoSsr } from "../src/components/utils/NoSsr";
import { WaitlistForm } from "../src/components/landing/WaitlistForm";
import { config } from "../src/utils/config";
import { paths } from "../src/utils/paths";
import { useAmplitude } from "../src/hooks/utils/useAmplitude";
import { useAuth } from "../src/hooks/utils/useAuth";
import { useInterval } from "../src/hooks/utils/useInterval";
import { useRouter } from "next/router";
import NextImage from "next/image";
import NextLink from "next/link";
import React, { useEffect, useState } from "react";
import screenshot from "../public/static/img/landing-screenshot.png";

const Entry: React.FunctionComponent = () => {
  const { user, loading } = useAuth();
  const router = useRouter();
  const { logEvent, events } = useAmplitude();

  if (loading) {
    return (
      <Box w="24">
        <Spinner />
      </Box>
    );
  }

  if (user) {
    router.push(paths.all);
    return null;
  } else {
    return (
      <Box>
        <NextLink passHref href={paths.register}>
          <Button
            variant="ghost"
            color="inherit"
            onClick={() => logEvent(events.index.clickRegister)}
          >
            Register
          </Button>
        </NextLink>
        <NextLink passHref href={paths.signIn}>
          <Button
            variant="ghost"
            color="inherit"
            onClick={() => logEvent(events.index.clickSignIn)}
          >
            Sign in
          </Button>
        </NextLink>
      </Box>
    );
  }
};

interface FeatureProps {
  title: string;
  text: string;
  icon: React.ReactNode;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack textAlign={"left"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

const Footer: React.FC = () => {
  return (
    <Box p={8} textAlign={"center"} color={"gray.600"}>
      <Text>
        © 2025{" "}
        <Link href="https://davidbarrell.me" isExternal>
          David Barrell
        </Link>
        . All rights reserved.
      </Text>
    </Box>
  );
};

const RegisterCTA: React.FC = () => {
  const { logEvent, events } = useAmplitude();

  if (config.registrationsClosed) {
    return (
      <Container maxW={"lg"} bg={"white"} boxShadow={"xl"} rounded={"lg"} p={6}>
        <Heading
          as={"h2"}
          fontSize={{ base: "xl", sm: "2xl" }}
          textAlign={"center"}
          mb={5}
        >
          Join the waitlist for early access
        </Heading>
        <WaitlistForm />
      </Container>
    );
  }

  return (
    <Container maxW={"lg"} bg={"white"} boxShadow={"xl"} rounded={"lg"} p={6}>
      <Heading
        as={"h2"}
        fontSize={{ base: "xl", sm: "2xl" }}
        textAlign={"center"}
        mb={5}
      >
        Ready to give it a spin?
      </Heading>
      <NextLink href={paths.register} passHref>
        <Button
          color="white"
          bg={"#3B3A78"}
          px={6}
          _hover={{
            bg: "blue.700",
          }}
          onClick={() => logEvent(events.index.clickRegister)}
        >
          Register
        </Button>
      </NextLink>
    </Container>
  );
};

export const Landing: React.FunctionComponent = ({ }) => {
  const roles = ["musicians", "actors", "singers", "choristers", "students"];
  const [roleIndex, setRoleIndex] = useState<number>(0);
  const { logEvent, events } = useAmplitude();

  useEffect(() => {
    logEvent(events.index.loadPage);
  }, [logEvent, events]);

  useInterval(() => {
    setRoleIndex((roleIndex + 1) % roles.length);
  }, 2500);

  const currentRole = roles[roleIndex];

  return (
    <Box bg={"gray.50"}>
      <Box
        px={6}
        py={3}
        h="16"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading size="md">trackshare</Heading>
        <NoSsr>
          <Entry />
        </NoSsr>
      </Box>
      <Divider />
      <Stack
        w="full"
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        pt={{ base: 20, md: 28 }}
        pb={10}
        px="5"
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          Give your{" "}
          <Text
            as={"span"}
            px={2}
            sx={{ backgroundColor: "#3B3A78", color: "white" }}
          >
            {currentRole}
          </Text>{" "}
          control
        </Heading>
        <Text fontSize="xl" color={"gray.500"} maxW={"3xl"}>
          trackshare is a simple, web-based multitrack player for sharing
          rehearsal tracks.
        </Text>
        <Box pb={"2"} px={"10"} maxW="container.lg">
          <NextImage
            alt="Screenshot of trackshare player"
            src={screenshot}
            priority
          />
          <Box mt={3}>
            <NextLink href={paths.demo} passHref>
              <Button
                color="white"
                bg={"#3B3A78"}
                px={6}
                _hover={{
                  bg: "blue.700",
                }}
                onClick={() => logEvent(events.index.clickDemo)}
              >
                Open demo
              </Button>
            </NextLink>
          </Box>
        </Box>
        <Container py={4}>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <Box>
              <Text fontSize="xx-large" fontWeight="bold">
                200+
              </Text>
              <Text fontSize={"xl"} color={"gray.600"}>
                Users
              </Text>
            </Box>
            <Box>
              <Text fontSize="xx-large" fontWeight="bold">
                2,000+
              </Text>
              <Text fontSize={"xl"} color={"gray.600"}>
                Uploads
              </Text>
            </Box>
            <Box>
              <Text fontSize="xx-large" fontWeight="bold">
                22,000+
              </Text>
              <Text fontSize={"xl"} color={"gray.600"}>
                Views
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
        <Container py={4} maxW={"container.md"}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Feature
              icon={<UsersColorIcon w={10} h={10} />}
              title={"Make the best of remote rehearsals"}
              text={
                "Give your musicians everything they need to learn their parts themselves."
              }
            />
            <Feature
              icon={<HeadsetColorIcon w={10} h={10} />}
              title={"Control the mix"}
              text={
                "Adjust the balance of tracks and choose which parts you hear."
              }
            />
            <Feature
              icon={<ShareColorIcon w={10} h={10} />}
              title={"Share easily"}
              text={
                "Use secret links to share playlists and tracks with anyone – no need for them to create an account."
              }
            />
            <Feature
              icon={<MultiDeviceColorIcon w={10} h={10} />}
              title={"Accessible anywhere"}
              text={
                "trackshare works on any device, right in your web browser."
              }
            />
          </SimpleGrid>
        </Container>
        <RegisterCTA />

        <Stack spacing={5} alignItems={"center"}>
          <Heading>Coming soon</Heading>
          <Text color={"gray.600"}>
            Register now for early access, and follow along as we add more
            features, such as:
          </Text>
          <Box color={"gray.600"}>
            <UnorderedList textAlign={"left"}>
              <ListItem>In-app recording</ListItem>
              <ListItem>Track groups</ListItem>
              <ListItem>Rehearsal marks</ListItem>
              <ListItem>Annotations</ListItem>
              <ListItem>Tempo controls</ListItem>
            </UnorderedList>
          </Box>
        </Stack>
      </Stack>
      <Footer />
    </Box>
  );
};

export default Landing;
